<template>
  <div>
    <div style="display: flex">
      <div class="col" style="text-align: left">
        <button
          v-can="'mesh.add_cycle'"
          v-if="allows_crud"
          class="btn btn-secondary btn-sm btn-add"
          v-b-modal.new-cycle-modal
        >
          Agregar {{ $getVisibleNames("mesh.cycle", false, "Ciclo Formativo") }}
        </button>
      </div>
    </div>
    <b-table
      v-if="render_table"
      :items="profileCycleList"
      :fields="fields"
      :filter="input_search"
      thead-class="primary-header"
    >
      <template #head(selected)>
        <b-checkbox
          v-model="all_selected"
          @input="selectAll"
          v-b-tooltip.v-secondary="
            all_selected ? 'Deseleccionar Todos' : 'Seleccionar Todos'
          "
        ></b-checkbox>
      </template>
      <template #cell(selected)="row">
        <b-checkbox
          v-model="row.item.selected"
          @input="selectCycle()"
        ></b-checkbox>
      </template>
      <template #cell(init_semester)="row">
        <!-- <span v-b-tooltip.v-secondary="'Semestre inicial'">{{
          row.item.init_semester
        }}</span> -->
        <b-form-input
          v-if="row.item.selected"
          size="sm"
          class="m-0"
          type="number"
          v-b-tooltip.v-secondary="'Nivel inicial'"
          v-model="row.item.init_semester"
        >
        </b-form-input>
        <b-form-input
          v-else
          size="sm"
          class="m-0"
          type="number"
          :disabled="!row.item.selected"
        >
        </b-form-input>
      </template>
      <template #cell(end_semester)="row">
        <!-- <span v-b-tooltip.v-secondary="'Semestre final'">{{
          row.item.end_semester
        }}</span> -->
        <EgressProfileCycleEndSemester
          :selected="row.item.selected"
          :semester_amount="egressProfile.semester_amount"
          :init_semester="row.item.init_semester"
          :end_semester="row.item.end_semester"
          :profile_cycle_id="row.item.id"
          @updated="slotEndSemesterUpdated"
        >
        </EgressProfileCycleEndSemester>
      </template>
      <template #cell(egress_profiles)="row">
        <div
          v-for="egress_profile_id in row.item.egress_profiles"
          :key="egress_profile_id"
          class="profiles"
        >
          <template
            v-for="egress_profile in egressProfiles.filter(
              (x) => x.id == egress_profile_id
            )"
          >
            <span
              :key="egress_profile.id"
              v-b-tooltip.v-secondary="egress_profile.professional_title"
              >{{ egress_profile.professional_title }}</span
            >
          </template>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast, generateUniqueId } from "@/utils/utils";

export default {
  name: "EgressProfileCycleTable",
  components: {
    EgressProfileCycleEndSemester: () =>
      import("./EgressProfileCycleEndSemester.vue"),
  },
  props: {
    egress_profile_id: {
      type: Number,
    },
    input_search: {
      type: String,
      default: "",
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    selection_mode: {
      type: Boolean,
      default: false,
    },
    selected_ids: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      fields: [
        this.selection_mode
          ? { key: "selected", label: "Seleccionar", thStyle: { width: "1%" } }
          : null,
        {
          key: "name",
          label: "Nombre",
          thStyle: { align: "center", width: "20%" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "init_semester",
          label: "Inicio",
          thStyle: { width: "15%" },
          thClass: "text-center",
        },
        {
          key: "end_semester",
          label: "Fin",
          thStyle: { width: "15%" },
          thClass: "text-center",
        },
        !this.selection_mode
          ? { key: "description", label: "Descripción", sortable: true }
          : null,
        !this.selection_mode
          ? { key: "certification", label: "Certificación", sortable: true }
          : null,
        this.allows_crud ? { key: "egress_profiles", label: "Perfiles" } : null,
        this.allows_crud ? { key: "actions", label: "Acciones" } : null,
      ],
      profile_cycles_list: [],
      all_selected: false,
      render_table: true,
    };
  },
  computed: {
    ...mapGetters({
      egressProfiles: names.EGRESS_PROFILES,
      profileCycles: names.PROFILE_CYCLES,
      careers: names.CAREERS,
      faculties: names.FACULTIES,
    }),
    profileCycleList() {
      return [...this.profile_cycles_list].sort((a, b) => {
        if (b.init_semester !== a.init_semester)
          return a.init_semester - b.init_semester;
        // Si init_semester es igual, ordena por end_semester de mayor a menor
        return a.end_semester - b.end_semester;
      });
    },
    egressProfile() {
      if (!this.egress_profile_id) return [];
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
  },
  methods: {
    fetchEgressprofileCycles() {
      this.profile_cycles_list = [];
      this.$store.dispatch(names.FETCH_CYCLES).then((response) => {
        response.forEach((cycle) => {
          let profile_cycle = this.profileCycles.find(
            (x) =>
              x.cycle == cycle.id && x.egress_profile == this.egress_profile_id
          );
          if (profile_cycle) {
            this.profile_cycles_list.push({
              id: profile_cycle.id,
              name: cycle.name,
              egress_profile: profile_cycle.egress_profile,
              cycle: profile_cycle.cycle,
              order: profile_cycle.order,
              init_semester: profile_cycle.init_semester,
              end_semester: profile_cycle.end_semester,
              selected: true,
            });
          } else {
            this.profile_cycles_list.push({
              id: generateUniqueId(),
              name: cycle.name,
              egress_profile: this.egress_profile_id,
              cycle: cycle.id,
              order: 1,
              init_semester: cycle.init_semester,
              end_semester: cycle.end_semester,
              selected: false,
            });
          }
        });
      });
      this.$emit("cycle_changed", this.profile_cycles_list);
    },
    selectCycle() {
      this.$emit("cycle_changed", this.profile_cycles_list);
    },
    selectAll(event) {
      this.profile_cycles_list.forEach((cycle) => {
        cycle.selected = event;
      });
    },
    slotCreatedCycle() {
      this.$bvModal.hide("new-cycle-modal");
    },
    slotUpdatedCycle(cycle) {
      this.$bvModal.hide(`edit-cycle-modal-${cycle.id}`);
    },
    slotEndSemesterUpdated(end_semester, profile_cycle_id) {
      const index = this.profile_cycles_list.findIndex(
        (x) => x.id == profile_cycle_id
      );
      if (index != -1) {
        this.profile_cycles_list[index].temp_end_semester = end_semester;
      }
      this.selectCycle();
    },
    askForDeleteCycle(cycle_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.cycle",
          false,
          "Ciclo Formativo"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_CYCLE, cycle_id).then(() => {
            toast(
              this.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo") +
                " eliminado."
            );
          });
        }
      });
    },
  },
  created() {
    this.fetchEgressprofileCycles();
    this.$store.dispatch(names.FETCH_PROFILE_CYCLES);
  },
};
</script>

<style scoped>
.btn-add-cycle {
  background: var(--primary-color);
  border: none;
  margin-bottom: 0.5em;
  float: right;
}
.add-icon {
  margin-right: -2%;
  font-size: bold;
}
.profiles {
  background-color: var(--secondary-color);
  border-radius: 4px;
}
</style>